import { registerApplication, start, navigateToUrl } from "single-spa";

let d = document.documentElement;
let cw = d.clientWidth || 750;
d.style.fontSize = (16 / 1920) * cw + "px";

if (sessionStorage.getItem("token") === null){
    navigateToUrl("/login");
}

if (location.href === window.location.origin + "/") {
    if (sessionStorage.getItem("token") === null) {
        navigateToUrl("/login");
    } else {
        navigateToUrl("/dashboard");
    }
}

window.addEventListener(
    "orientationchange" in window ? "orientationchange" : "resize",
    (function () {
        function c() {
            let d = document.documentElement;
            let cw = d.clientWidth || 750;
            d.style.fontSize = (16 / 1920) * cw + "px";
        }
        c();
        return c;
    })(),
    false
);


registerApplication({
    name: "@aim-mf/dashboard",
    app: () => System.import("@aim-mf/dashboard"),
    activeWhen: "/dashboard",
});

registerApplication({
    name: "@aim-mf/login",
    app: () => System.import("@aim-mf/login"),
    activeWhen: "/login",
});

registerApplication({
    name: "@aim-mf/alert-management",
    app: () => System.import("@aim-mf/alert-management"),
    activeWhen: "/alert-management",
});

start();
